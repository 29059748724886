

































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  private isMobile = false;
  private isAuthenticated = false;

  private mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  }

  private beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize);
  }

  private onResize() {
    this.isMobile = window.innerWidth < 600;
  }
}
