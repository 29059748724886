export interface AppConfig {
  env: string;
  issuer: {
    schemaName: string;
  };
  api: {
    buyBCAPIUrl: string;
    orgbookAPIBaseUrl: string;
  };
}

export class Configuration {
  public app!: AppConfig;
}
