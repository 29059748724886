










/* eslint-disable */
import Vue from "vue";
import Header from "./views/Header.vue";
import Footer from "./views/Footer.vue";
import Verify from "./views/Verify.vue";
import VueQrcode from "vue-qrcode";

export default Vue.extend({
  name: "App",

  components: {
    "buybc-footer": Footer,
    "buybc-header": Header,
    "buybc-verify": Verify,
    "vue-qrcode": VueQrcode,
  },

  data: () => ({
    //
  }),
});
