import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";
import { AppConfig } from "@/models/appConfig";
import { RootState } from "@/models/storeState";
import { configuration } from "@/store/modules/configuration/configuration";

class AppStore extends Vuex.Store<RootState> {
  private static instance: Store<RootState>;
  private constructor(config: AppConfig) {
    Vue.use(Vuex);
    const storeOptions: StoreOptions<RootState> = {
      state: {
        version: "1.0.0", // a simple property
      },
      modules: {
        configuration
      },
    };

    super(storeOptions);
  }

  static getInstance(config?: AppConfig): AppStore {
    if (!AppStore.instance) {
      if (!config) {
        throw new Error(
          "No AppConfig was provided and no Store instance is available"
        );
      }
      AppStore.instance = new AppStore(config);
    }

    return AppStore.instance;
  }
}

export default AppStore;