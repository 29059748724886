import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Verify from "@/views/Verify.vue";
import Landing from "@/views/Landing.vue";
import PageNotFound from "../views/PageNotFound.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/verify/:id",
    name: "Verify",
    component: Verify,
  },
  {
    path: "*",
    name: "NotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path == "/verify" || to.path == "/verify/") {
    next("/");
  } else {
    next();
  }
});

export default router;
