






















































































/* eslint-disable */
import { Component, Vue, Watch } from "vue-property-decorator";
import axios from "axios";
import LoadingComponent from "../components/Loading.vue";
import router from "vue-router";

@Component({
  components: {
    LoadingComponent: LoadingComponent,
  },
})
export default class Landing extends Vue {
  private config = this.$store.getters["configuration/getConfiguration"]
  private isLoading = false;
  private searchResults: any[] = [];
  // private autocompleteList: any[] = [];
  // private searchedOrg: any = {};
  // private isAutocompleteLoading = false;
  private isMobile = false;
  private searchText = "";
  private selectedOrg: {
    source_id: string;
  } = {
    source_id: "",
  };
  private registrationId = "";
  private numBusinessesFound = 0;
  private page = 1;
  private hasSearched = false;
  private firstIndex = 1;
  private lastIndex = 20;
  private nextPage = "";
  private prevPage = "";
  private searchResultTableHeaders: any[] = [
    {
      text: "Business Name",
      value: "names[0].text",
      sortable: false,
    },
    {
      text: "Registration Number",
      value: "source_id",
      sortable: false,
    },
  ];

  private mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  }

  private handleClick(row: any) {
    this.selectedOrg = row;
  }

  private getBusinessResults(searchText: string) {
    this.isLoading = true;
    this.hasSearched = false;
    axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIBaseUrl +
        "/v4/search/topic/facets?q=" +
        searchText +
        "&page_size=10&page=1",
    }).then((res: any) => {
      this.searchResults = res.data.objects.results;
      this.numBusinessesFound = res.data.objects.total;
      this.page = res.data.objects.page;
      this.firstIndex = res.data.objects.first_index;
      this.lastIndex = res.data.objects.last_index;
      this.isLoading = false;
      this.hasSearched = true;
    });
  }

  @Watch("page")
  private onPageChanged(page: any) {
    this.isLoading = true;
    axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIBaseUrl +
        "/v4/search/topic/facets?q=" +
        this.searchText +
        "&page_size=10&page=" +
        this.page,
    }).then((res: any) => {
      this.page = res.data.objects.page;
      this.searchResults = res.data.objects.results;
      this.firstIndex = res.data.objects.first_index;
      this.hasSearched = true;
      this.lastIndex = res.data.objects.last_index;
      this.isLoading = false;
    });
  }

  @Watch("selectedOrg")
  private orgSelected() {
    this.isLoading = true;
    this.searchText = "";
    this.registrationId = this.selectedOrg.source_id;
    this.$router.push("/verify/" + this.registrationId);
  }

  private beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize);
  }

  private onResize() {
    this.isMobile = window.innerWidth < 600;
  }
}
