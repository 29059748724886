








































































































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import LoadingComponent from "../components/Loading.vue";
import router from "vue-router";
import jq from "node-jq";

@Component
export default class Verify extends Vue {
  private config = this.$store.getters["configuration/getConfiguration"];
  private isLoading = false;
  private hasErrors = false;
  private errorMessage = "";
  private orgDataLoaded = false;
  private rules = [(v: any) => v.length <= 280 || "Max 280 characters"];
  private feedback = "";
  private hasSent = false;
  private orgId = "";
  private iafLicenses: any[] = [];
  private gotLicenses = false;
  private licenseDetails: {
    organization_url: string;
    approvedproducts: any;
  } = {
    organization_url: "",
    approvedproducts: [],
  };
  private hasActive = false;
  private licenseId = "";
  private foundMatchingLicense = false;
  private loadingText = "Checking Buy BC Partner status...";
  private credentials: any[] = [];
  private businessUrl = "";
  private licenseInfoLoaded = false;
  private headers = [
    {
      text: "Approved Products",
      align: "start",
      sortable: false,
      value: "name",
    },
  ];
  private licenseArray: any = [];

  private products: { name: string }[] = [{ name: "Nothing to display" }];
  private selectedOrg: {
    name: any;
    id: number;
    credential_set: { id: number };
  } = {
    name: "",
    id: -1,
    credential_set: { id: -1 },
  };

  private mounted() {
    this.isLoading = true;
    this.orgId = this.$route.params.id;
    axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIBaseUrl +
        "/v4/search/topic/facets?q=" +
        this.orgId,
    }).then((res: any) => {
      if (res.data.objects.results.length < 1) {
        this.hasErrors = true;
      } else {
        this.selectedOrg.id = res.data.objects.results[0].id;
        this.selectedOrg.name = res.data.objects.results[0].names[0].text;
        this.selectedOrg.credential_set.id =
          res.data.objects.results[0].credential_set.id;
        this.orgDataLoaded = true;
        this.getCredentials();
      }
    });
  }

  private async parseProducts(productList: any) {
    for (let i = 0; i < productList.length; i++) {
      this.products.push({ name: productList[i] });
    }
  }

  private parseUrl(url: string): void {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "http://" + url;
    }
    window.open(url, "_blank");
  }

  private async getCredentials() {
    await axios({
      method: "GET",
      url:
        this.config.app.api.orgbookAPIBaseUrl +
        "/topic/" +
        this.selectedOrg.id +
        "/credentialset",
    }).then((res) => {
      this.credentials = res.data;
      this.checkCredentials();
    });
  }

  private async sendButtonClicked() {
    let product_List = "";
    if (this.products[0].name !== "Nothing to display") {
      product_List = "Approved Products:";
      for (let i = 0; i < this.products.length; i++) {
        const element = this.products[i].name;
        product_List = product_List + " \n "+ element ;
      }
    }

    await axios({
      method: "POST",
      url: this.config.app.api.buyBCAPIUrl + "/feedback",
      data: {
        company_name: this.selectedOrg.name,
        company_id: this.orgId,
        verification: this.hasActive ? "" : "yes",
        approved_products: product_List,
        feedback_message: this.feedback,
      },
    }).catch((error) => {
      this.errorMessage = error.message;
    });

    this.hasSent = true;
  }

  private async checkCredentials() {
    var activeCredId = "";
    for (let i = 0; i < this.credentials.length; i++) {
      await axios({
        method: "GET",
        url:
          this.config.app.api.orgbookAPIBaseUrl +
          "/v3/credentialtype/" +
          this.credentials[i].credentials[0].credential_type.id,
      }).then(async (res: any) => {
        var licenseStatus = "";

        if (
          this.credentials[i].credentials[0].credential_type.description ===
          this.config.app.issuer.schemaName
        ) {
          // Buy BC License, get active attributes
          for (let k = 0; k < this.credentials[i].credentials.length; k++) {
            if (this.credentials[i].credentials[k].revoked === false) {
              // Credential is active (latest)
              await axios({
                method: "GET",
                url:
                  this.config.app.api.orgbookAPIBaseUrl +
                  "/v3/credential/" +
                  this.credentials[i].credentials[k].id,
              }).then(async (res: any) => {
                licenseStatus = res.data.attributes[2].value;
                if (licenseStatus == "Active") {
                  this.hasActive = true;
                  activeCredId = res.data.credential_id;
                  this.licenseId = res.data.attributes[0].value;
                  await axios({
                    method: "GET",
                    url: this.config.app.api.buyBCAPIUrl + "/licenses",
                  })
                    .then((res: any) => {
                      let num_licenses = 0;
                      this.licenseInfoLoaded = true;
                      this.iafLicenses = res.data.licenses;
                      if (this.iafLicenses) {
                        this.gotLicenses = true;
                      } else {
                        this.gotLicenses = false;
                        this.hasErrors = true;
                        this.errorMessage =
                          "Could not fetch license information from API"; // Error message from API
                      }
                    })
                    .catch((err: any) => {
                      this.gotLicenses = false;
                      this.hasErrors = true;
                      this.errorMessage =
                        "Could not fetch license information from API"; // Error message from API
                    });
                  if (this.gotLicenses) {
                    for (let i = 0; i < this.iafLicenses.length; i++) {
                      if (this.iafLicenses[i].licensenumber == this.licenseId) {
                        this.licenseDetails = this.iafLicenses[i];
                        this.foundMatchingLicense = true;
                        this.businessUrl = this.licenseDetails.organization_url;
                        if (this.licenseDetails.approvedproducts.length > 0) {
                          this.products = [];
                        }
                        this.parseProducts(
                          this.licenseDetails.approvedproducts
                        );
                        break;
                      }
                    }
                  }
                }
              });
            }
          }
        }
      });
    }
    if (this.hasActive) {
      await axios({
        url:
          this.config.app.api.orgbookAPIBaseUrl +
          "/v3/credential/" +
          activeCredId +
          "/verify",
      }).then((res: any) => {
        this.isLoading = false;
        this.loadingText = "Is a verified Buy BC Partner";
      });
    } else {
      this.isLoading = false;
      this.loadingText = "Is not a verified Buy BC Partner";
    }
  }
}
